import React from "react";
import FileSaver from "file-saver";

import { Container } from "../../components/atoms";
import {
  BodyRegular2,
  Header1,
  Header3,
  Header4,
} from "../../components/styled/Typography";
import { Colors, StorageKeys } from "../../constants";
import {
  faArrowRight,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";

import "./index.css";
import { sendMessageToParent } from "../../helpers";

const AbhaListPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const onClickDownloadAbhaCard = (abhaAddress) => {
    if (localStorage.getItem(StorageKeys.SOURCE) === "MyTatva") {
      sendMessageToParent("DOWNLOAD_FILE", {
        url: abhaAddress?.image,
        fileName: abhaAddress?.abha_address + ".png",
      });
    } else {
      FileSaver.saveAs(abhaAddress?.image, abhaAddress?.abha_address + ".png");
    }
  };

  const renderABDMItem = (item, index) => {
    return (
      <div key={index.toString()} className={`abdm-item`}>
        <Header3 color={Colors.TEXT_PRIMARY}>{item?.abha_address}</Header3>
        {item?.image && (
          <div
            onClick={() => onClickDownloadAbhaCard(item)}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon
              icon={faFileArrowDown}
              className="abdm-item-download-icon"
            />
          </div>
        )}
      </div>
    );
  };

  const onPressAddNewAddress = () => {
    navigate(`/new-abha-address`, {
      state: {
        phoneNumber: state?.phoneNumber,
        abhaNumber: state?.abhaNumber,
      },
    });
  };

  return (
    <Container
      showBack
      onPressBack={() => {
        navigate(-1);
      }}
    >
      <div className="abhalist-screen-container">
        <div>
          <Header1 color={Colors.TEXT_PRIMARY}>Link your ABHA address</Header1>
          <BodyRegular2 color={Colors.TEXT_SECONDARY}>
            ABHA address is a unique username that allows you to share and
            access your health records digitally
          </BodyRegular2>
        </div>
        <div className="abdm-item-list-container">
          {(state?.abha_addresses ?? []).map(renderABDMItem)}
        </div>
        {localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup" && (
          <div className="abhalist-btn" onClick={onPressAddNewAddress}>
            <Header4 color={Colors.ORANGE_60}>Add New Address</Header4>
            <FontAwesomeIcon icon={faArrowRight} className="right-arrow" />
          </div>
        )}
      </div>
    </Container>
  );
};

export default AbhaListPage;
