const StorageKeys = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  TRANSACTION_ID: "transaction_id",
  LOGIN_FLOW: "login_flow",
  LOGGED_IN_USER: "logged_in_user",
  UTM_SOURCE: "utm_source",
  UTM_MEDIUM: "utm_medium",
  X_TOKEN: "x_token",
  SOURCE: "source",
  TIME_LEFT: "time_left",
  SESSION_EXPIRED_ALERT: "session_expired_alert",
};

export default StorageKeys;
