import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";

import {
  BodyBold2,
  BodyRegular2,
  Header1,
} from "../../components/styled/Typography";
import { Colors, Events, StorageKeys } from "../../constants";
import { Container, Button, TnCModal } from "../../components/atoms";
import AadharCardInput from "../../components/organisms/AadharCardInput";
import { useAlert } from "../../context/alert/AlertContext";
import { generateSession, requestEnrolmentOtp } from "../../apis";
import { trackEvent } from "../../analytics";
import { useSession } from "../../context/session/SessionContext";

import "./index.css";
import { sendMessageToParent } from "../../helpers";

const SignupPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const tncRef = useRef(null);
  const { showAlert } = useAlert();
  const { startTimer, resetTimer } = useSession();

  const [tnc, setTnc] = useState(false);
  const [aadharNumber, setAadharNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const onClickViewTnC = () => {
    tncRef?.current?.show();
  };

  const onClickLogin = () => {
    trackEvent(Events.ABHA_LOGIN_CLICKED, {});
    navigate(`/login`);
  };

  const backHandler = () => {
    sendMessageToParent("NAVIGATE_BACK");
  };

  const handleError = (response) => {
    if (response?.data?.loginId === "Invalid LoginId") {
      showAlert("Entered input is invalid");
      return;
    }
    showAlert(response?.error?.message ?? "Something went wrong!");
    return;
  };

  const onPressCreateYourAbha = async () => {
    trackEvent(Events.ABHA_CREATE_ABHA_CLICKED, {
      aadhar_number: aadharNumber,
    });
    const payload = {
      loginType: "aadhaar",
      loginValue: aadharNumber,
    };
    setLoading(true);
    const session_response = await generateSession();
    if (session_response?.code === 1) {
      const response = await requestEnrolmentOtp(payload);
      if (response?.code === 1) {
        startTimer();
        trackEvent(Events.ABHA_AADHAR_OTP_SENT, {
          aadhar_number: aadharNumber,
        });
        navigate(`/otp?mode=aadhar`, {
          state: { message: response?.data?.message, aadhar: aadharNumber },
        });
      } else {
        handleError(response);
      }
    } else {
      handleError(session_response);
    }
    setLoading(false);
  };

  const storeUTMDetails = () => {
    try {
      localStorage.setItem(
        StorageKeys.UTM_SOURCE,
        searchParams.get("utm_source") ??
          localStorage.getItem(StorageKeys.UTM_SOURCE)
      );
      localStorage.setItem(
        StorageKeys.UTM_MEDIUM,
        searchParams.get("utm_medium") ??
          localStorage.getItem(StorageKeys.UTM_MEDIUM)
      );
      localStorage.setItem(
        StorageKeys.SOURCE,
        searchParams.get("source") ?? localStorage.getItem(StorageKeys.SOURCE)
      );
    } catch (error) {
      console.log("[error storing search params]: ", error);
    } finally {
      logMoengageEvent();
    }
  };

  const logMoengageEvent = () => {
    trackEvent(Events.ABHA_PAGE_LANDING, {});
  };

  useEffect(() => {
    storeUTMDetails();
  }, []);

  useEffect(() => {
    resetTimer();
  }, [location]);

  useEffect(() => {
    // Save the current state to prevent going back
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // For Chrome compatibility
    };

    window.history.pushState(null, null, window.location.href); // Prevent going back

    const handlePopState = () => {
      window.history.pushState(null, null, window.location.href);
    };

    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  return (
    <Container
      showBack={
        (searchParams.get(StorageKeys.SOURCE) ||
          localStorage.getItem(StorageKeys.SOURCE)) === "MyTatva"
      }
      onPressBack={backHandler}
    >
      <div className="signup-container">
        <div className="top-app-bar">
          <img
            src={require("../../assets/images/tatvacare_logo.png")}
            className="tatvacare-logo"
          />
        </div>
        <div className="form-container">
          <div>
            <Header1 color={Colors.BLACK}>Create/View ABHA account</Header1>
            <BodyRegular2 color={Colors.TEXT_SECONDARY}>
              Issued by the National Health Authority through the Ayushman
              Bharat Digital Mission (ABDM)
            </BodyRegular2>
          </div>
          {/* Aadhar card */}
          <AadharCardInput
            aadhar={aadharNumber}
            setAadhar={(number) => setAadharNumber(number)}
          />
          <div className="tnc-container">
            <div
              onClick={() => setTnc(!tnc)}
              className="tnc-icon-container"
              style={{ cursor: "pointer" }}
            >
              {tnc ? (
                <FontAwesomeIcon icon={faSquareCheck} className="tnc-icon" />
              ) : (
                <FontAwesomeIcon icon={faSquare} className="tnc-icon" />
              )}
            </div>
            <BodyRegular2 color={Colors.TEXT_PRIMARY}>
              I agree with the terms and conditions
            </BodyRegular2>
            <div style={{ cursor: "pointer" }}>
              <BodyBold2 color={Colors.ORANGE_60} onClick={onClickViewTnC}>
                View
              </BodyBold2>
            </div>
          </div>
          <Button
            buttonSize={"large"}
            disabled={aadharNumber.length !== 12 || !tnc}
            loading={loading}
            type={"primary"}
            width={"full"}
            title={"Create/View your ABHA"}
            onPress={onPressCreateYourAbha}
            rightIcon={
              <FontAwesomeIcon
                icon={faArrowRight}
                className="enabled-arrow-icon"
              />
            }
            disabledRightIcon={
              <FontAwesomeIcon
                icon={faArrowRight}
                className="disabled-arrow-icon"
              />
            }
          />
          <div>
            <BodyRegular2 color={Colors.TEXT_PRIMARY}>
              Already have an existing ABHA account?
            </BodyRegular2>
            <div onClick={onClickLogin} style={{ cursor: "pointer" }}>
              <BodyBold2 color={Colors.ORANGE_60}>
                Log In Using Mobile Number
              </BodyBold2>
            </div>
          </div>
        </div>
      </div>
      <TnCModal ref={tncRef} />
    </Container>
  );
};

export default SignupPage;
