import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";

import { ReactComponent as DownloadDocument } from "../../assets/icons/document_download.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import { Container, Button, LogoutModal } from "../../components/atoms";
import {
  BodyBold1,
  BodyBold2,
  BodyRegular2,
  Header1,
  Header4,
} from "../../components/styled/Typography";
import { Colors, Events, StorageKeys } from "../../constants";
import AbhaCard from "../../components/molecules/abhaCard";
import { downloadAbhaCard, getDetailsByMobile } from "../../apis/profile";
import { logout, trackEvent } from "../../analytics";
import { useAlert } from "../../context/alert/AlertContext";

import "./index.css";
import FileSaver from "file-saver";
import { decryptData } from "../../apis/api";
import { useSession } from "../../context/session/SessionContext";
import { sendMessageToParent } from "../../helpers";

const ProfilePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const { resetTimer } = useSession();
  const [searchParams] = useSearchParams();

  const logoutRef = useRef(null);

  const [abhaAccounts, setAbhaAccounts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const isUserLoggedIn =
    localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup" ||
    localStorage.getItem(StorageKeys.LOGIN_FLOW) === "login";

  const showActivityButtons =
    localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup"; //Only activate if coming from signup flow

  const onClickCreateNewAbha = () => {
    logoutRef?.current?.show();
  };

  const onPressDownload = async () => {
    const payload = {
      xToken: localStorage?.getItem(StorageKeys.X_TOKEN),
      ABHANumber: decryptData(localStorage.getItem(StorageKeys.LOGGED_IN_USER)),
    };
    setDownloading(true);
    const response = await downloadAbhaCard(payload);
    if (response?.code === 1) {
      if (localStorage.getItem(StorageKeys.SOURCE) === "MyTatva") {
        sendMessageToParent("DOWNLOAD_FILE", {
          url: response?.data?.attachmentUrl,
          fileName: response?.data?.attachmentPath,
        });
      } else {
        FileSaver.saveAs(
          response?.data?.attachmentUrl,
          response?.data?.attachmentPath
        );
      }
    } else {
      showAlert(response?.error?.message ?? "Something went wrong!");
    }
    setDownloading(false);
  };

  const renderAadharCard = (item, index) => {
    return <AbhaCard key={index.toString()} abha_details={item} />;
  };

  const onClickEditPhone = () => {
    navigate(`/update?mode=number`, {
      state: {
        phoneNumber: abhaAccounts?.[activeIndex]?.mobile ?? "",
        aadhar: abhaAccounts?.[activeIndex]?.aadhar_number ?? null,
        email: abhaAccounts?.[activeIndex]?.email ?? "",
        ABHANumber: abhaAccounts?.[activeIndex]?.abha_number,
      },
    });
  };

  const onClickViewAbhaAddressInfo = () => {
    navigate(`/abha-list`, {
      state: {
        abha_addresses: abhaAccounts?.[activeIndex]?.abha_addresses ?? [],
        phoneNumber: abhaAccounts?.[activeIndex]?.mobile ?? "",
        abhaNumber: abhaAccounts?.[activeIndex]?.abha_number ?? "",
      },
    });
  };

  const onClickEditEmail = () => {
    navigate(`/update?mode=email`, {
      state: {
        email: abhaAccounts?.[activeIndex]?.email ?? "",
        aadhar: abhaAccounts?.[activeIndex]?.aadhar_number ?? null,
        phoneNumber: abhaAccounts?.[activeIndex]?.mobile ?? "",
        ABHANumber: abhaAccounts?.[activeIndex]?.abha_number,
      },
    });
  };

  const fetchAbhaAccounts = async () => {
    if (!isFetching) {
      setIsFetching(true);
      const queryParams = {
        mobile: location.state?.phoneNumber ?? searchParams.get("phoneNumber"),
      };
      const details_by_mobile_response = await getDetailsByMobile(queryParams);
      if (details_by_mobile_response?.code === 1) {
        const keyToMatch = "abha_number";
        const valueToMove = decryptData(
          localStorage.getItem(StorageKeys.LOGGED_IN_USER)
        );
        setAbhaAccounts(
          details_by_mobile_response?.data?.sort((a, b) => {
            if (a[keyToMatch] === valueToMove) return -1;
            if (b[keyToMatch] === valueToMove) return 1;
            return 0;
          }) ?? []
        );
        // setAbhaAccounts(details_by_mobile_response?.data ?? []);
      }
      setIsFetching(false);
    }
  };

  const logMoengageEvent = () => {
    trackEvent(Events.ABHA_USER_DETAIL_LANDING, {});
  };

  const onPressYes = () => {
    localStorage.removeItem(StorageKeys.LOGIN_FLOW);
    localStorage.removeItem(StorageKeys.TRANSACTION_ID);
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
    localStorage.removeItem(StorageKeys.X_TOKEN);
    localStorage.removeItem(StorageKeys.LOGGED_IN_USER);
    logout();
    navigate("/signup");
  };

  const storeUTMDetails = () => {
    try {
      localStorage.setItem(
        StorageKeys.UTM_SOURCE,
        searchParams.get("utm_source") ??
          localStorage.getItem(StorageKeys.UTM_SOURCE)
      );
      localStorage.setItem(
        StorageKeys.UTM_MEDIUM,
        searchParams.get("utm_medium") ??
          localStorage.getItem(StorageKeys.UTM_MEDIUM)
      );
      localStorage.setItem(
        StorageKeys.SOURCE,
        searchParams.get("source") ?? localStorage.getItem(StorageKeys.SOURCE)
      );
    } catch (error) {
      console.log("[error storing search params]: ", error);
    }
  };

  useEffect(() => {
    fetchAbhaAccounts();
  }, [location]);

  useEffect(() => {
    logMoengageEvent();
    storeUTMDetails();
    if (!!!localStorage.getItem(StorageKeys.LOGIN_FLOW)) {
      //If no login flow detected, stop the timer
      resetTimer();
    }
  }, []);

  return isFetching ? (
    <div className="profile-loader-container">
      <Lottie
        animationData={require("../../assets/animations/abha_validator.json")}
        autoPlay
        loop
        style={{
          height: "100px",
          width: "100px",
        }}
      />
    </div>
  ) : (
    <Container
      showBack
      onPressBack={() => {
        if (isUserLoggedIn) {
          logoutRef?.current?.show();
        } else {
          sendMessageToParent("NAVIGATE_BACK");
        }
      }}
    >
      <div className="profile-page-container">
        <div className="top-sheet">
          <Header1
            color={Colors.BLACK}
            style={{
              textAlign: "left",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          >
            Your ABHA Account
          </Header1>
          <Carousel
            showArrows={true}
            useKeyboardArrows
            showThumbs={false}
            centerMode
            centerSlidePercentage={90}
            infiniteLoop={false}
            showStatus={false}
            showIndicators={abhaAccounts.length > 1}
            onChange={(index) => {
              setActiveIndex(index);
            }}
            renderIndicator={(clickHandler, isSelected) => {
              return (
                <div
                  style={{
                    height: "4px",
                    width: isSelected ? "40px" : "4px",
                    borderRadius: "2px",
                    backgroundColor: isSelected
                      ? Colors.PRIMARY_70
                      : Colors.GRAY_30,
                  }}
                />
              );
            }}
          >
            {abhaAccounts.map(renderAadharCard)}
          </Carousel>
          {localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup" &&
            decryptData(localStorage.getItem(StorageKeys.LOGGED_IN_USER)) ===
              abhaAccounts[activeIndex]?.abha_number && (
              <Button
                buttonSize={"small"}
                disabled={false}
                loading={downloading}
                leftIcon={<DownloadDocument className="icon" />}
                disabledLeftIcon={<DownloadDocument className="icon" />}
                type={"secondary"}
                width={"dynamic"}
                title="Download Card"
                onPress={onPressDownload}
                customButtonStyle={{
                  alignSelf: "center",
                }}
              />
            )}
        </div>
        <div className="other-information-container">
          <Header4 color={Colors.BLACK}>Other Information</Header4>
          <div className="other-information">
            {/* MOBILE */}
            <div className="other-info-item-container">
              <div className="other-info-detail">
                <BodyRegular2 color={Colors.TEXT_TERTIARY}>
                  Mob. Linked to ABHA
                </BodyRegular2>
                <BodyBold1 color={Colors.TEXT_PRIMARY}>
                  {abhaAccounts?.[activeIndex]?.mobile}
                </BodyBold1>
              </div>
              {showActivityButtons && (
                <div className="edit-info-container" onClick={onClickEditPhone}>
                  <Edit className="icon" />
                  <Header4 color={Colors.ORANGE_60}>Edit Info</Header4>
                </div>
              )}
            </div>
            {/* ABHA ADDRESS */}
            <div className="other-info-item-container">
              <div className="other-info-detail">
                <BodyRegular2 color={Colors.TEXT_TERTIARY}>
                  ABHA Address
                </BodyRegular2>
                <BodyBold1 color={Colors.TEXT_PRIMARY}>
                  {abhaAccounts?.[activeIndex]?.abha_addresses?.[
                    abhaAccounts?.[activeIndex]?.abha_addresses?.length - 1
                  ]?.abha_address ?? "--"}
                </BodyBold1>
              </div>
              <div
                className="edit-info-container"
                onClick={onClickViewAbhaAddressInfo}
              >
                <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                <Header4 color={Colors.ORANGE_60}>View Info</Header4>
              </div>
            </div>
            {/* EMAIL ADDRESS */}
            <div className="other-info-item-container">
              <div className="other-info-detail">
                <BodyRegular2 color={Colors.TEXT_TERTIARY}>
                  Email Linked to ABHA
                </BodyRegular2>
                <BodyBold1 color={Colors.TEXT_PRIMARY}>
                  {abhaAccounts?.[activeIndex]?.email ?? "--"}
                </BodyBold1>
              </div>
              {showActivityButtons && (
                <div className="edit-info-container" onClick={onClickEditEmail}>
                  <Add className="icon" />
                  <Header4 color={Colors.ORANGE_60}>Add Info</Header4>
                </div>
              )}
            </div>
          </div>
          <div className="divider-line" />
          <div className="create-new-abha-container">
            <BodyRegular2 color={Colors.TEXT_PRIMARY}>
              Want to create/link more ABHA accounts?
            </BodyRegular2>
            <div onClick={onClickCreateNewAbha} style={{ cursor: "pointer" }}>
              <BodyBold2 color={Colors.ORANGE_60}>
                Create/Link New ABHA
              </BodyBold2>
            </div>
          </div>
        </div>
      </div>
      <LogoutModal ref={logoutRef} onPressYes={onPressYes} />
    </Container>
  );
};

export default ProfilePage;
